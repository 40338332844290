<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="newsPlace">
    <!-- Start: Search Header -->
    <div v-if="topList[0]" style="float: right;">
      최종편집시간: {{this.moment(topList[0].batchTime).format('YYYY-MM-DD HH:mm:ss')}}
      <p>
        최종편집인: {{topList[0].editor}}
      </p>
    </div>
    <div class="flex flex-wrap items-center flex-grow" style="margin-bottom: 10px">
      <!-- <div style="margin-left: 20px">
        <v-select style="width: 150px" placeholder="제목+본문" v-model="subject"
          :options="options.subjects"/>
      </div> -->
      <!-- <div style="margin-left: 10px">
        <v-select style="width: 150px" placeholder="최신 순" v-model="sortBy"
          :options="options.sorts"/>
      </div> -->
      <!-- <div style="margin-left: 10px;"> -->
        <!-- <date-range-picker 
          v-model="dateRange" 
          :locale-data="locale"
          :opens="opens"
          :ranges="ranges"
        > -->
          <!--Optional scope for the input displaying the dates -->
          <!-- <div id="dates" slot="range" ></div> -->
        <!-- </date-range-picker> -->
      <!-- </div> -->
      <div style="margin-left: 10px">
        <vs-input v-on:keyup.enter="searchNewsList()" class="inputx" placeholder="기사 검색" v-model="searchVar.filterWords"/>
      </div>
      <div class="vs-table--search" style="margin: 0px 15px">
        <vs-button
          ref="loadableButton"
          id="button-with-loading"
          color="primary"
          type="border"
          class="p-2 cursor-pointer vs-con-loading__container"
          @click="searchNewsList()"
        >검색</vs-button>
      </div>
    </div>
    <!-- End: Search Header -->
    
    <!-- List 1 -->
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <vs-list id="newsList" ref="newsList">
          <vs-list-header title="후보리스트" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 910" @change="newsListUpdate" :list="newsList.slice((currentx-1)*itemsPerPage, (currentx)*itemsPerPage)" group="newsList" class="p-2 cursor-move">
            <vs-list-item :style="[($route.query.category == 77 && listItem.pastMain.match('1;') && listItem.naverRed) ? {'background-color': '#FFEEEE', 'color': 'red' } :
                                  ($route.query.category == 77 && listItem.pastMain.match('1;') && !listItem.naverRed) ? {'background-color': '#FFEEEE' } :
                                  ($route.query.category == 77 && !listItem.pastMain.match('1;') && listItem.naverRed) ? {'color': 'red' } :
                                  ($route.query.category == 0 && listItem.pastMain2 == 'p') ? {'background-color': '#EEEEFF' } :
                                  ($route.query.category == 78 && listItem.pastMain.match('2;')) ? {'background-color': '#FFFFEE' } :
                                  (listItem.news_grade == $route.query.category * 100 + 12 ? {'background-color': '#EEFFEE' } : {})]" 
              v-for="(listItem, index) in newsList.slice((currentx-1)*itemsPerPage, (currentx)*itemsPerPage)" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
              <span v-html="listItem.writer" />
              <vs-avatar v-if="$route.query.category == 0" @click="removeHubo(listItem.id, listItem.b_sbj)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/images/trash_icon_for_news_hubo.png'" />
              <vs-avatar @click="newsview(listItem.id, 0)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item :style="[($route.query.category == 77 && listItem.pastMain.match('1;') && listItem.naverRed) ? {'background-color': '#FFEEEE', 'color': 'red' } :
                                  ($route.query.category == 77 && listItem.pastMain.match('1;') && !listItem.naverRed) ? {'background-color': '#FFEEEE' } :
                                  ($route.query.category == 77 && !listItem.pastMain.match('1;') && listItem.naverRed) ? {'color': 'red' } :
                                  ($route.query.category == 0 && listItem.pastMain2 == 'p') ? {'background-color': '#EEEEFF' } :
                                  ($route.query.category == 78 && listItem.pastMain.match('2;')) ? {'background-color': '#FFFFEE' } :
                                  (listItem.news_grade == $route.query.category * 100 + 12 ? {'background-color': '#EEFFEE' } : {})]" 
            v-else v-for="(listItem, index) in newsList.slice((currentx-1)*itemsPerPage, (currentx)*itemsPerPage)" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
            <span v-html="listItem.writer" />
            <vs-avatar v-if="$route.query.category == 0" @click="removeHubo(listItem.id, listItem.b_sbj)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/images/trash_icon_for_news_hubo.png'" />
            <vs-avatar @click="newsview(listItem.id, 0)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
          </vs-list-item>
        </vs-list>
        <div>
            <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
        </div>
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
      </div>
      <div class="vx-col w-1/2" v-show="$route.query.category != 999">
        <!-- <div v-if="$route.query.category == 0" class="dailyTopic" style="margin-bottom: 10px; display: table-cell; max-width: 100%;">
          <div style="display: inline-block; vertical-align: top;">
            <vs-button class="p-2 cursor-pointer vs-con-loading__container" color="primary" type="filled" v-on:click="setDailyTopic(dailyTopic)" 
                      :disabled="dailyTopic.title.length > 10">데일리 토픽 배치</vs-button> 
            <p style="margin-left: 20px;">데일리 토픽 시리즈 선택: </p>
            <v-select style="width: 160px; margin-left: 20px;" placeholder="데일리 토픽" label="name" v-model="dailyTopic.selected"
              :options="seriesArray"/>
          </div>
          <div style="display: inline-block;">                    
            <form class="drag-drop" ref="fileform">
              <label class="drop-files"><p>여기를 클릭하거나 파일을 드래그해서 넣어주세요</p>
              <input type="file" @change="addFile"></label>
              <progress max="100" :value.prop="uploadPercentage"></progress>
            </form>
            <img :src="preview" style=" width: 189px; max-height: 189px; min-height: 100px;">
          </div>
        </div> -->
        <vs-button v-if="$route.query.category == 99" class="p-2 cursor-pointer vs-con-loading__container" :disabled="sendButtonDisable" color="primary" type="filled" v-on:click="sendDataToServer" >배치 확정</vs-button> 
        <vs-button v-else class="p-2 cursor-pointer vs-con-loading__container" :disabled="sendButtonDisable" color="primary" type="filled" v-on:click="newsPlacePreview()" >미리보기 (배치 확정)</vs-button> 
        <vs-list id="topList" ref="topList">
          <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearTopList()" >헤드라인 비우기</vs-button> 
          <vs-list-header :title="'헤드라인: ' + topList.length + '/' + numOfTop" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 910" :list="topList" group="newsList" class="p-2 cursor-move" @change="mainTopChange" style="heigth: 500px;">
            <vs-list-item :style="[$route.query.category != 77 && $route.query.category != 78 && (listItem.news_grade == $route.query.category * 100 + 11 || (listItem.pastMain2 == 'p')) ? {'background-color': '#EEEEFF' } : 
                                  $route.query.category == 77 && (listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain.match('1;')) && listItem.naverRed ? {'background-color': '#FFEEEE', 'color': 'red' } :
                                  $route.query.category == 77 && (listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain.match('1;')) && !listItem.naverRed ? {'background-color': '#FFEEEE' } :
                                  $route.query.category == 77 && listItem.naverRed ? {'color': 'red' } :
                                  $route.query.category == 78 && (listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain.match('2;')) ? {'background-color': '#FFFFEE' } :
                                  (listItem.news_grade == $route.query.category * 100 + 12 ? {'background-color': '#EEFFEE' } : {})]" 
              v-for="(listItem, index) in topList" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 1)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item :style="[$route.query.category != 77 && $route.query.category != 78 && (listItem.news_grade == $route.query.category * 100 + 11 || (listItem.pastMain2 == 'p')) ? {'background-color': '#EEEEFF' } : 
                                  $route.query.category == 77 && (listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain.match('1;')) && listItem.naverRed ? {'background-color': '#FFEEEE', 'color': 'red' } :
                                  $route.query.category == 77 && (listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain.match('1;')) && !listItem.naverRed ? {'background-color': '#FFEEEE' } :
                                  $route.query.category == 77 && listItem.naverRed ? {'color': 'red' } :
                                  $route.query.category == 78 && (listItem.news_grade == $route.query.category * 100 + 11 || listItem.pastMain.match('2;')) ? {'background-color': '#FFFFEE' } :
                                  (listItem.news_grade == $route.query.category * 100 + 12 ? {'background-color': '#EEFFEE' } : {})]" 
            v-else v-for="(listItem, index) in topList" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
            <span v-html="listItem.writer" />
            <vs-avatar @click="topListOrder(index, 'up')" slot="avatar" :src="'https://cdnimage.dailian.co.kr/images/newsPlaceArrowUp.png'" />
            <vs-avatar @click="topListOrder(index, 'down')" slot="avatar" :src="'https://cdnimage.dailian.co.kr/images/newsPlaceArrowDown.png'" />
            <vs-avatar @click="newsview(listItem.id, 1)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
          </vs-list-item>
        </vs-list>
          
        <vs-list id="subTopList" ref="subTopList" v-if="numOfSubTop">
          <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearSubTopList()" >중탑 비우기</vs-button>
          <div v-if="listSortFlg" class="flex flex-wrap items-center float-right" style="margin: 8px 5px;">
              <vs-radio style="margin: 0px 5px;" @change="topListChange" color="success" v-model="middleSortFlg" vs-value="auto">자동정렬</vs-radio>
              <vs-radio style="margin: 0px 5px;" @change="topListChange" color="danger" v-model="middleSortFlg" vs-value="menual">수동배치</vs-radio>
          </div>
          <vs-list-header :title="'중탑: ' + subTopList.length + '/' + numOfSubTop" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 910" :list="subTopList" group="newsList" class="p-2 cursor-move" @change="topListChange">
            <vs-list-item :style="[$route.query.category != 77 && (listItem.news_grade == $route.query.category * 100 + 11 || (listItem.pastMain2 == 'p')) ? {'background-color': '#EEEEFF' } : 
                                  (listItem.news_grade == $route.query.category * 100 + 11) || ($route.query.category == 77 && listItem.pastMain == 88) ? {'background-color': '#FFEEEE' } :
                                  (listItem.news_grade == $route.query.category * 100 + 12 ? {'background-color': '#EEFFEE' } : {})]" 
              v-for="(listItem, index) in subTopList" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 2)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item :style="[$route.query.category != 77 && (listItem.news_grade == $route.query.category * 100 + 11 || (listItem.pastMain2 == 'p')) ? {'background-color': '#EEEEFF' } : 
                                  (listItem.news_grade == $route.query.category * 100 + 11) || ($route.query.category == 77 && listItem.pastMain == 88) ? {'background-color': '#FFEEEE' } :
                                  (listItem.news_grade == $route.query.category * 100 + 12 ? {'background-color': '#EEFFEE' } : {})]" 
            v-else v-for="(listItem, index) in subTopList" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
            <span v-html="listItem.writer" />
            <vs-avatar @click="subTopListOrder(index, 'up')" slot="avatar" :src="'https://cdnimage.dailian.co.kr/images/newsPlaceArrowUp.png'" />
            <vs-avatar @click="subTopListOrder(index, 'down')" slot="avatar" :src="'https://cdnimage.dailian.co.kr/images/newsPlaceArrowDown.png'" />
            <vs-avatar @click="newsview(listItem.id, 2)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
          </vs-list-item>
        </vs-list>
        <vs-list id="entBatch3List" ref="entBatch3List" v-if="numOfEntBatch3">
          <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearList('entBatch3')" >중탑 비우기</vs-button>
          <vs-list-header :title="'특수배치1: ' + entBatch3List.length + '/' + numOfEntBatch3" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 910" :list="entBatch3List" group="newsList" class="p-2 cursor-move" @change="topListChange">
            <vs-list-item class="relative" v-for="(listItem, index) in entBatch3List" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 3)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
              <div class="batchImgUpload" :style="[{'bottom': '0px', 'right': '0px'}]">                    
                <form class="drag-drop" ref="fileform">
                  <label class="drop-files">
                    <img v-if="listItem.batchImg" :src="listItem.batchImg">
                    <p v-else>이미지 업로드</p>
                    <input type="file" @change="addBatchImg($event, index)">
                  </label>
                </form>
              </div>
            </vs-list-item>
          </draggable>
          <vs-list-item class="relative" v-else v-for="(listItem, index) in entBatch3List" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
            <span v-html="listItem.writer" />
            <vs-avatar @click="newsview(listItem.id, 3)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
            <div class="batchImgUpload" :style="[{'bottom': '0px', 'right': '0px'}]">                    
              <form class="drag-drop" ref="fileform">
                <label class="drop-files">
                  <img v-if="listItem.batchImg" :src="listItem.batchImg">
                  <p v-else>이미지 업로드</p>
                  <input type="file" @change="addBatchImg($event, index)">
                </label>
              </form>
            </div>
          </vs-list-item>
        </vs-list>
        <vs-list class="relative" id="entBatch3List" ref="entBatch4List" v-if="numOfEntBatch4">
          <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearList('entBatch4')" >중탑 비우기</vs-button>
          <vs-list-header :title="'특수배치1: ' + entBatch4List.length + '/' + numOfEntBatch4" color="primary"></vs-list-header>
          <draggable v-if="windowWidth > 910" :list="entBatch4List" group="newsList" class="p-2 cursor-move" @change="topListChange">
            <vs-list-item v-for="(listItem, index) in entBatch4List" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 4)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
            </vs-list-item>
          </draggable>
          <vs-list-item v-else v-for="(listItem, index) in entBatch4List" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
            <span v-html="listItem.writer" />
            <vs-avatar @click="newsview(listItem.id, 4)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
          </vs-list-item>
        </vs-list>
        <vs-list class="relative" id="naverRankingList" ref="naverRankingList" v-if="naverRankingList.list.length">
          <vs-list-header :title="'네이버 랭킹'" color="primary"></vs-list-header>
          <span v-html="makeHourTxt(naverRankingList.hour)" />
          <vs-list-item v-for="(listItem, index) in naverRankingList.list.slice(0,15)" :key="index" 
            :title="makeTitle(listItem.title, listItem.count, listItem.rank)" 
            :subtitle="makeSubTitle(listItem.articleId, listItem.createdAt, '', '')">
            <vs-avatar slot="avatar" :src="listItem.thumbnail" />
          </vs-list-item>
        </vs-list>
        <div v-if="numOfSportsExBatch">
          <vs-button class="p-2 cursor-pointer vs-con-loading__container" :disabled="sportsExBatch.length != numOfSportsExBatch" color="primary" type="filled" v-on:click="sendDataSportsEx" >배치 확정</vs-button> 
          <vs-list class="relative" id="entBatch3List" ref="entBatch4List">
            <vs-button class="p-2 cursor-pointer float-right" color="primary" type="border" v-on:click="clearList('sportsExBatch')" >비우기</vs-button>
            <vs-list-header :title="'아시안게임 헤드라인: ' + sportsExBatch.length + '/' + numOfSportsExBatch" color="primary"></vs-list-header>
            <draggable v-if="windowWidth > 910" :list="sportsExBatch" group="newsList" class="p-2 cursor-move" @change="topListChange">
              <vs-list-item v-for="(listItem, index) in sportsExBatch" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
                <span v-html="listItem.writer" />
                <vs-avatar @click="newsview(listItem.id, 5)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
              </vs-list-item>
            </draggable>
            <vs-list-item v-else v-for="(listItem, index) in asianBatch" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
              <span v-html="listItem.writer" />
              <vs-avatar @click="newsview(listItem.id, 5)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
            </vs-list-item>
          </vs-list>
        </div>
        <div v-if="medalData.state && $route.query.category == sportsExNum">
            <div class="vs-header-list-primary vs-list--title">{{ medalData.title }}</div>
            <table>
              <tr>
                <th>순위</th>
                <th>금메달</th>
                <th>은메달</th>
                <th>동메달</th>
              </tr>
              <tr>
                <td><vs-input-number v-model="medalData.ranking"/></td>
                <td><vs-input-number v-model="medalData.gold"/></td>
                <td><vs-input-number v-model="medalData.silver"/></td>
                <td><vs-input-number v-model="medalData.bronze"/></td>
              </tr>
            </table>
            <vs-button class="p-2 cursor-pointer vs-con-loading__container" color="primary" type="filled" v-on:click="sendMedalSetting" >적용</vs-button> 
          </div>
      </div>
      <vs-table v-if="activeUserInfo.level >= 10 || activeUserInfo.permission.placeLog" ref="table" pagination search :max-items="20" :data="history" style="margin:0 auto; width: 600px;">
        <template slot="thead">
          <vs-th sort-key="editor">수정</vs-th>
          <vs-th sort-key="reg_time">날짜</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td><div @click="showHistory(tr.idx, tr.batch_items1, tr.batch_items2)" style="font-weight: bold">{{ tr.editor }}</div></vs-td>
              <vs-td><div @click="showHistory(tr.idx, tr.batch_items1, tr.batch_items2)">{{ moment(tr.reg_time).format('YYYY-MM-DD HH:mm:ss') }}</div></vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <!-- Start: 뉴스 상세보기 팝업 -->
      <div>
        <vs-popup ref="newsPlace-preview" classContent="newsPlace-preview" title="배치 미리보기" :active.sync="activePromptPreview">
          <div class="items-center">
            <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0; z-index: 100;">
              <div v-if="$route.query.category == 78 && 2 < topList.filter(e => e.pastMain.match('2;')).length">
                새로운 기사를 3개 이상 배치해주세요.
                <div v-if="activeUserInfo.level >= 11">
                  <vs-button vs-type="gradient" color="success" icon="create" @click="sendDataToServer">배치 확정</vs-button>
                </div>
              </div>
              <div v-else style="margin:0 auto;">
                <vs-button vs-type="gradient" color="success" icon="create" @click="sendDataToServer">배치 확정</vs-button>
              </div>
            </div>
            <div style="padding-bottom: 20px;" v-html="newsstand"></div>
            <div v-if="$route.query.category == 77" class="newsChannel">
              <div class="textList">
                <div class="textItem" :data="data" :key="index" v-for="(data, index) in topList.slice(0,4)">
                  <a target="_blank" :href="'https://www.dailian.co.kr/news/view/' + data.id">{{data.b_sbj}}</a>
                </div>
              </div>
              <div class="columnList">
                <div class="columnItem" :data="data" :key="index" v-for="(data, index) in topList.slice(4,6)">
                  <a target="_blank" :href="'https://www.dailian.co.kr/news/view/' + data.id">
                    <div class="imgBox">
                      <img :src="'https://cdnimage.dailian.co.kr/news/icon/' + data.icon2">
                    </div>
                    <div class="textBox">
                      <div class="channelImgText">{{data.b_sbj}}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div v-if="$route.query.category == 78" class="newsChannel">
              <div class="textList">
                <div class="textItem" :data="data" :key="index" v-for="(data, index) in topList.slice(0,3)">
                  <a target="_blank" :href="'https://www.dailian.co.kr/news/view/' + data.id">{{data.b_sbj}}</a>
                </div>
              </div>
              <div class="columnList">
                <div class="columnItem" :data="data" :key="index" v-for="(data, index) in topList.slice(3,5)">
                  <a target="_blank" :href="'https://www.dailian.co.kr/news/view/' + data.id">
                    <div class="imgBox">
                      <img :src="'https://cdnimage.dailian.co.kr/news/icon/' + data.icon2">
                    </div>
                    <div class="textBox">
                      <div class="channelImgText">{{data.b_sbj}}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div v-else-if="numOfTop > 0">
              <div class="thumbnail-preview" style="position: relative;" :data="data" :key="index" v-for="(data, index) in topList">
                <!-- <div style="width: 106px; height: 98px; background-color: #002ADD; border-radius: 0 0 10px 0; position: absolute; top: 0px; left: 0px; text-align: center; vertical-align: middle;">
                  <p style="color: white; font-size: 32px; line-height: 36px; font-weight: 500; letter-spacing: -2px; margin-top: 10px;">HEAD</p>
                  <p style="color: white; font-size: 32px; line-height: 36px; font-weight: 500; letter-spacing: -2px; margin-top: 4px; ">LINE</p>
                </div> -->
                <img :src="'https://cdnimage.dailian.co.kr/news/icon/' + data.icon2" width="785px"/>
              </div>
            </div>
            <div class="lineSliderContainer" v-if="numOfEntBatch3 > 0">
              <div class="lineSliderDiv" v-for="(data, index) in entBatch3List" :key="index">
                <img :src="data.batchImg">
                <div class="greyFilter"></div>
                <div class="seriesName">
                  <p>{{data.series_name}}</p>
                </div>
                <div class="title">
                  <p>{{data.b_sbj.replace(/^\[.*\]/, '')}}</p>
                </div>
              </div>
            </div>
          </div>
        </vs-popup>
      </div>
      <!-- End: 뉴스 상세보기 팝업 -->

      <!-- Start: 뉴스 상세보기 팝업 -->
      <div class="news-detail-form">
        <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
          <div class="items-center">
            <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0;">
            <div style="margin:0 auto;">
              <vs-button vs-type="gradient" color="success" @click="editNews(selectedId)">기사수정</vs-button>
              <vs-button v-if="placeButton == 0 || placeButton == 2" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 1)">헤드라인 배치</vs-button>
              <vs-button v-if="(placeButton == 0 || placeButton == 1) && numOfSubTop > 0" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 2)">중탑 배치</vs-button>
              <vs-button v-if="placeButton != 3 && numOfEntBatch3 > 0" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 3)">특수배치1</vs-button>
              <vs-button v-if="placeButton != 4 && numOfEntBatch4 > 0" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 4)">특수배치2</vs-button>
              <vs-button v-if="placeButton != 0" vs-type="gradient" color="primary" @click="setNewsPlace(selectedId, 0)">배치 제외</vs-button>
            </div>
              <vs-button v-if="activeUserInfo.level == 9" vs-type="flat" color="danger" icon="delete_sweep" @click="deleteNews(selectedId)">기사삭제</vs-button>
            </div>
            <div class="news-detail">
              <div v-html="newsDetail.data && newsDetail.data.preview ? newsDetail.data.preview : ''"></div>
            </div>
            <div class="flex" style="justify-content: space-around; margin-bottom: 20px; padding: 20px 0; border-bottom: 1px solid #ccc; border-top: 1px solid #ccc;">
              <!-- 미리보기: 
              <vs-radio color="success" v-model="previewType" vs-value="iPhone1">아이폰1(375)</vs-radio>
              <vs-radio color="success" v-model="previewType" vs-value="iPhone2">아이폰2(414)</vs-radio>
              <vs-radio color="success" v-model="previewType" vs-value="android1">안드로이드1(360)</vs-radio>
              <vs-radio color="success" v-model="previewType" vs-value="android2">안드로이드2(420)</vs-radio>
              <vs-radio color="success" v-model="previewType" vs-value="pc">PC</vs-radio> -->
              
              <vs-button color="blue" size="small" @click="previewType = 'iPhone1'" style="width: 25%" >iOS 미리보기</vs-button>
              <vs-button color="danger" size="small" @click="previewType = 'android1'" style="width: 25%" >Android 미리보기</vs-button>
              <vs-button color="grey" size="small" @click="previewType = 'pc'" style="width: 25%" >PC 미리보기</vs-button>
            </div>
            
            <div id="view_con" :class="previewType == 'pc' ? 'news-detail' : 
              previewType == 'iPhone1' ? 'newsDetailMobile iPhone1' : 
              previewType == 'iPhone2' ? 'newsDetailMobile iPhone2' :
              previewType == 'android1' ? 'newsDetailMobile android1' :
              previewType == 'android2' ? 'newsDetailMobile android2' :
              previewType == 'android3' ? 'newsDetailMobile android3' : ''">
              <div v-html="newsDetail.data && newsDetail.data.article ? newsDetail.data.article : newsDetail.data"></div>
            </div>
          </div>
        </vs-popup>
      </div>
      
      <!-- Start: 히스토리 팝업 -->
      <div class="news-detail-form">
        <vs-popup ref="popup-batchHistory" classContent="popup-batchHistory" title="배치 이력" :active.sync="batchHistoryModalActive">
          <div class="items-center">
            <vs-list id="batchHistory" ref="batchHistory">
              <vs-list-header title="배치이력" color="primary"></vs-list-header>
              <vs-list-item v-for="(listItem, index) in historyItems" :key="index" :title="makeTitle(listItem.b_sbj, listItem.viewed, listItem.sort)" :subtitle="makeSubTitle(listItem.id, listItem.d_pass, listItem.series_name, listItem.photoSetName)">
                <span v-html="listItem.writer" />
                <vs-avatar @click="newsview(listItem.id, 0)" slot="avatar" :src="'https://cdnimage.dailian.co.kr/news/icon/' + listItem.icon" />
              </vs-list-item>
            </vs-list>
          </div>
        </vs-popup>
      </div>
      <!-- End: 뉴스 상세보기 팝업 -->
      <!-- <vs-prompt
        class="newsPlace-preview"
        vs-title="배치 미리보기"
        vs-accept-text= "배치확정"
        vs-cancel-text = "취소"
        vs-button-cancel = "border"
        @vs-accept="sendDataToServer"
        :vs-active.sync="activePromptPreview">
        <div class="my-4">
          <div class="thumbnail-preview" :data="data" :key="index" v-for="(data, index) in topList">
            <img :src="'//cdnimage.dailian.co.kr/news/icon/' + data.icon"/>
          </div>
        </div>
      </vs-prompt> -->
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
// import AddNewDataSidebar from '../AddNewDataSidebar.vue';
// import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
// import 'vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css';
// import AddNewDataSidebar from '../AddNewDataSidebar.vue';
import draggable from 'vuedraggable'
import moment from 'moment';

export default {
  components: {
    draggable,
    'v-select': vSelect,
    // AddNewDataSidebar
    // DateRangePicker,
  },
  data() {
    return {
      moment: moment,
      previewType: 'android1',
      windowWidth: window.innerWidth,
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
          '오늘': [moment(), moment()],
          '1주일': [moment().subtract(1, 'week'), moment()],
          '한달': [moment().subtract(1, 'month'), moment()],
          '이번 달': [moment().startOf('month'), moment().endOf('month')],
          '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      dateRange: { // used for v-model prop
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
      options: {
        sorts: [
          '최신+조회 순',
          '최신 순',
          '조회 순',
        ],
        subjects: [
          '제목+본문',
          '제목',
          '본문'
        ]
      },
      subject: '제목+본문',
      sortBy: '최신+조회 순',
      selectedId: '',
      selected: [],
      newsList: [],
      topList: [],
      subTopList: [],
      entBatch3List: [],
      entBatch4List: [],
      naverRankingList: {
        hour: 0, list: []
      },
      newsstand: '',
      newsDetail: '',
      numOfTop: 6,
      numOfSubTop: 18,
      numOfEntBatch3: 0,
      numOfEntBatch4: 0,
      numOfSportsExBatch: 0,
      sportsExBatch: [],
      sportsExNum: 55,
      medalData: {
        state: 1,
        ranking: "-",
        gold: "-",
        silver: "-",
        bronze: "-",
        title: '파리올림픽 대한민국 메달집계',
        link: '/olympic2024'
      },
      numOfNews: 0,
      numOfPages: 0,
      itemsPerPage: 20,
      currentx: 1,
      isMounted: false,
      activePromptPreview: false,
      newsDetailModalActive: false,
      placeButton: 0,
      sendButtonDisable: true,
      seriesArray: [],
      preview: '',
      batchImgNum: 0,
      dailyTopic: {
        selected: null,
        image: '',
        title: '',
      },
      searchVar: {
        state: '1',
        category1: '*',
        startDate: moment().subtract(1, 'month'),
        endDate: moment(),
        filterWords: '',
        sortBy: 'published_time',
        subject: 'title_and_description'
      },
      files: [],
      uploadPercentage: 0,
      middleSortFlg: 'menual',
      listSortFlg: false,
      historyViewFlg: true,
      history: [],
      historyItems: [],
      batchHistoryModalActive: false,
    }
  },
  beforeRouteLeave (to, from, next) {
    this.activePromptPreview = false;
    this.newsDetailModalActive = false;
    this.batchHistoryModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    '$route.query': function() {
      console.log(this.$route.query);
      this.activePromptPreview = false;
      this.newsDetailModalActive = false;
      this.batchHistoryModalActive = false;
      this.created();
    },
    currentx: function() {
      this.scrollToTop()
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    scrollToTop() {
        window.scrollTo(0,0)
    },
    searchNewsList() {
      
      this.searchVar.startDate = this.dateRange.startDate
      this.searchVar.endDate = this.dateRange.endDate

      this.searchVar.sortBy = this.fixSortBy(this.sortBy)
      this.searchVar.subject = this.fixSubject(this.subject)

      this.created()
    },
    fixSortBy(sortBy) {
      
      if(this.options.sorts[0] == sortBy) {

        return 'published_time_before_view'
      }
      else if(this.options.sorts[1] == sortBy) {
        
        return 'published_time'
      }
      else if(this.options.sorts[2] == sortBy) {

        return 'view'
      }
      else {

        return ''
      }
    },
    fixSubject(subject) {

      if(this.options.subjects[0] == subject) {

        return 'title_and_description'
      }
      else if(this.options.subjects[1] == subject) {

        return 'title'
      }
      else if(this.options.subjects[2] == subject) {

        return 'description'
      }
      else {

        return ''
      }
    },
    async showHistory(id, itemList1, itemList2){
      if(!this.historyViewFlg){
        console.log(id, itemList1, itemList2)
      }else{
        this.$vs.loading({
          scale: 1.5
        });
        itemList1 = itemList1.split(',');
        itemList2 = itemList2.split(',');
        let newsList = [];
        let searchVar = {
          startRow: 0,
          itemsPerPage: 1,
          state: null,
          category1: null,
          series: null,
          rank: null,
          writer: null,
          searchType: 'newsId',
          searchText: '',
          startDate: '2000-01-01',
          endDate: moment().add(2, 'week').format('YYYY-MM-DD'),
          department: '',
        }
        // console.log(id, itemList1, itemList2);
        for(let i=0; i<itemList1.length; i++){
          searchVar.searchText = itemList1[i]
          let newsItem = await this.$http.post('/api/getNewsList', searchVar);
          let data = newsItem.data ? newsItem.data[1] ? newsItem.data[1][0] : {} : {};
          newsList.push(data);
        }
        if(itemList1.length < 3){
          for(let i=0; i<itemList2.length; i++){
            searchVar.searchText = itemList2[i]
            let newsItem = await this.$http.post('/api/getNewsList', searchVar);
            let data = newsItem.data ? newsItem.data[1] ? newsItem.data[1][0] : {} : {};
            newsList.push(data);
          }
        }
        // console.log(newsList)
        this.historyItems = newsList;
        this.batchHistoryModalActive = true;
        this.$vs.loading.close(); 
      } 
    },
    newsview(id, placeButton){
      console.log('event: ',id);
      this.placeButton = placeButton;
      this.selectedId = id;
      this.newsDetailModalActive = true;
      this.newsDetail = '';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getSingleNews/detail', {id: id})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
          // var images = response.data[1];
          // for(var i=0;i<images.length;i++){
          //   thisIns.sendData.arrayBase64.push(images[i].img);
          //   thisIns.sendData.arrayImageText.push(images[i].img_c);
          //   thisIns.sendData.arrayImageAlign.push(images[i].align);
          //   thisIns.sendData.arrayImageWidth.push(images[i].width);
          // }
          // var tags = response.data[2];
          // for(var i=0;i<tags.length;i++){
          //   thisIns.sendData.tag.push(tags[i].tag);
          // }
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    removeHubo(id, newsTitle) {
      var thisIns = this

      console.log('id:', id, ', newsTitle:', newsTitle)

      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'red',
        title: `후보 삭제`,
        text: `후보 '${newsTitle}'를(을) 삭제하시겠습니까?`,
        accept: function() {

          thisIns.$http.post('/api/updateHuboNews', {id: id, register: false})
            .then(function (response) {

                console.log(response)

                for(var idx in thisIns.newsList) {
                  
                  if(thisIns.newsList[idx].id == id) {

                    thisIns.newsList.splice(thisIns.newsList.indexOf(thisIns.newsList[idx]), 1)

                    thisIns.$vs.notify({
                      title:'후보 삭제 완료',
                      text: `'${newsTitle}'`,
                      color:'primary',
                      iconPack: 'feather',
                      icon:'icon-alert-circle'})

                    break
                  }
                }
            })
            .catch(function (error) {
              
                thisIns.$vs.notify({
                  title:'Error',
                  text: error,
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
                thisIns.$vs.loading.close();  
            });
        }
      })
    },
    setNewsPlace(newsID, placeButton){
      if(this.placeButton == 0){
        let tmpIdx = this.newsList.findIndex(e => e.id == newsID);
        if(placeButton == 1){ this.topList = [this.newsList[tmpIdx]].concat(this.topList) }
        else if(placeButton == 2){ this.subTopList = [this.newsList[tmpIdx]].concat(this.subTopList) }
        else if(placeButton == 3){ this.entBatch3List = [this.newsList[tmpIdx]].concat(this.entBatch3List) }
        else if(placeButton == 4){ this.entBatch4List = [this.newsList[tmpIdx]].concat(this.entBatch4List) }
        else if(placeButton == 5){ this.sportsExBatch = [this.newsList[tmpIdx]].concat(this.sportsExBatch) }
        this.newsList.splice(tmpIdx, 1)
        this.newsListUpdate('');
      }else if(this.placeButton == 1){
        let tmpIdx = this.topList.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.topList[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 2){ this.subTopList = [this.topList[tmpIdx]].concat(this.subTopList) }
        else if(placeButton == 3){ this.entBatch3List = [this.topList[tmpIdx]].concat(this.entBatch3List) }
        else if(placeButton == 4){ this.entBatch4List = [this.topList[tmpIdx]].concat(this.entBatch4List) }
        else if(placeButton == 5){ this.sportsExBatch = [this.newsList[tmpIdx]].concat(this.sportsExBatch) }
        this.topList.splice(tmpIdx, 1)
      }else if(this.placeButton == 2){
        let tmpIdx = this.subTopList.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.subTopList[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 1){ this.topList = [this.subTopList[tmpIdx]].concat(this.topList) }
        else if(placeButton == 3){ this.entBatch3List = [this.subTopList[tmpIdx]].concat(this.entBatch3List) }
        else if(placeButton == 4){ this.entBatch4List = [this.subTopList[tmpIdx]].concat(this.entBatch4List) }
        else if(placeButton == 5){ this.sportsExBatch = [this.newsList[tmpIdx]].concat(this.sportsExBatch) }
        this.subTopList.splice(tmpIdx, 1)
      }else if(this.placeButton == 3){
        let tmpIdx = this.entBatch3List.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.entBatch3List[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 1){ this.topList = [this.entBatch3List[tmpIdx]].concat(this.topList) }
        else if(placeButton == 2){ this.subTopList = [this.entBatch3List[tmpIdx]].concat(this.subTopList) }
        else if(placeButton == 4){ this.entBatch4List = [this.entBatch3List[tmpIdx]].concat(this.entBatch4List) }
        else if(placeButton == 5){ this.sportsExBatch = [this.newsList[tmpIdx]].concat(this.sportsExBatch) }
        this.entBatch3List.splice(tmpIdx, 1)
      }else if(this.placeButton == 4){
        let tmpIdx = this.entBatch4List.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.entBatch4List[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 1){ this.topList = [this.entBatch4List[tmpIdx]].concat(this.topList) }
        else if(placeButton == 2){ this.subTopList = [this.entBatch4List[tmpIdx]].concat(this.subTopList) }
        else if(placeButton == 3){ this.entBatch3List = [this.entBatch4List[tmpIdx]].concat(this.entBatch3List) }
        else if(placeButton == 5){ this.sportsExBatch = [this.newsList[tmpIdx]].concat(this.sportsExBatch) }
        this.entBatch4List.splice(tmpIdx, 1)
      }else if(this.placeButton == 5){
        let tmpIdx = this.entBatch4List.findIndex(e => e.id == newsID);
        if(placeButton == 0){ this.newsList.push(this.entBatch4List[tmpIdx]); this.newsListUpdate('');}
        else if(placeButton == 1){ this.topList = [this.entBatch4List[tmpIdx]].concat(this.topList) }
        else if(placeButton == 2){ this.subTopList = [this.entBatch4List[tmpIdx]].concat(this.subTopList) }
        else if(placeButton == 3){ this.entBatch3List = [this.entBatch4List[tmpIdx]].concat(this.entBatch3List) }
        else if(placeButton == 4){ this.entBatch4List = [this.entBatch3List[tmpIdx]].concat(this.entBatch4List) }
        this.sportsExBatch.splice(tmpIdx, 1)
      }
      this.topListChange();
      this.newsDetailModalActive = false;
    },
    topListOrder(index, method){
      let tmpList = {};
      if(method == 'up' && index > 0){
        tmpList = this.topList[index];
        this.topList[index] = this.topList[index - 1];
        this.topList[index - 1] = tmpList;
      }
      else if(method == 'down' && index < this.numOfTop - 1){
        tmpList = this.topList[index];
        this.topList[index] = this.topList[index + 1];
        this.topList[index + 1] = tmpList;
      }
      this.topList = this.topList.slice();
      this.topListChange();
      console.log(this.topList)
    },
    subTopListOrder(index, method){
      let tmpList = {};
      if(method == 'up' && index > 0){
        tmpList = this.subTopList[index];
        this.subTopList[index] = this.subTopList[index - 1];
        this.subTopList[index - 1] = tmpList;
      }
      else if(method == 'down' && index < this.numOfSubTop - 1){
        tmpList = this.subTopList[index];
        this.subTopList[index] = this.subTopList[index + 1];
        this.subTopList[index + 1] = tmpList;
      }
      this.subTopList = this.subTopList.slice();
      this.topListChange();
      console.log(this.subTopList)
    },
    editNews(newsId){
      console.log("edit: " + newsId);
      this.newsDetailModalActive = false;
      this.$router.push({path: '/writeArticles', query: { id: newsId}});
    },
    newsPlacePreview(){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      let sendData = [this.topList, this.subTopList, this.searchVar.category1];
      if(this.$route.query.category == 8){
        sendData.push(this.entBatch3List, this.entBatch4List)
      }
      this.$http.post('/api/newsPlace/preview', sendData)
        .then(function (response) {
          console.log(response)
          if(thisIns.numOfTop > 0){
            for(var i in thisIns.topList){
              thisIns.topList[i].icon2 = response.data.newThumbnail[i];
            }
          }
          console.log(thisIns.topList)
          thisIns.newsstand = response.data.newsstand;
          thisIns.activePromptPreview = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    setDailyTopic(dailyTopic){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/newsPlace/setDailyTopic', dailyTopic)
        .then(function () {

          thisIns.$vs.notify({
            title:'성공',
            text: '배치 확정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.sendButtonDisable = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
      
    },
    sendDataToServer(){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      let sendData = [this.topList, this.subTopList, this.searchVar.category1];
      if(this.numOfEntBatch3){
        let tmpData = JSON.parse(JSON.stringify(this.entBatch3List));
        for(const data of tmpData){
          if(data.batchImg){
            data.batchImg = data.batchImg.replace('/api/photo/viewImages?filename=', '').replace('https://cdnimage.dailian.co.kr/batchImg/', '');
          }
        }
        console.log('batchImg: ', tmpData)
        sendData[3] = tmpData;
      }
      if(this.numOfEntBatch4){
        sendData[4] = this.entBatch4List;
      }
      this.$http.post('/api/newsPlace/set', {data: sendData, uid: this.$store.state.AppActiveUser.id})
        .then(function () {

          thisIns.$vs.notify({
            title:'성공',
            text: '배치 확정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.activePromptPreview = false;
          thisIns.sendButtonDisable = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendDataSportsEx(){
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      let sendData = [this.sportsExBatch, [], this.sportsExNum];
      this.$http.post('/api/newsPlace/preview', sendData)
        .then(function (response) {
          console.log("preview: ",response)
          thisIns.$http.post('/api/newsPlace/set', {data: sendData, uid: thisIns.$store.state.AppActiveUser.id})
        })
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '배치 확정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.activePromptPreview = false;
          thisIns.sendButtonDisable = true;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    sendMedalSetting(){
      const thisIns = this;
      this.$http.post('/api/setting/setting', {code: this.sportsExNum, data: this.medalData})
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '메달 데이터 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.created();
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    makeHourTxt(hour){
      let tmpTxt = '';
      if(hour == 24){
        tmpTxt = `오후 11시 ~ 오전 0시`
      }else if(hour == 6){
        tmpTxt = `오전 0시 ~ 오전 6시`
      }else if(hour < 12){
        tmpTxt = `오전 ${hour-1}시 ~ 오전 ${hour}시`
      }else if(hour == 12){
        tmpTxt = `오전 11시 ~ 정오`
      }else{
        tmpTxt = `오후 ${hour-13}시 ~ 오후 ${hour-12}시`
      }
      return tmpTxt + `까지 집계된 결과입니다.`
    },
    makeTitle(subject, viewed, sort){
      let title = this.decodeEntities(subject)//.split('&lt;').join('<').split('&gt;').join('>')
                   + ' | ' + viewed;
      if(sort) title = sort + ': ' + title;
      return title;
    },
    makeSubTitle(id, pubTime, sName, pName){
      if(pubTime){
        pubTime = moment(pubTime).format("YYYY-MM-DD HH:mm")
      }
      let subTitle = id + ' | ' + pubTime;
      if(sName){
        subTitle += ' | ' + sName;
      }
      if(pName){
        subTitle += ' | ' + pName;
      }
      return subTitle;
    },
    decodeEntities(str) {
      let element = document.createElement('div');
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      return str;
    },
    clearTopList(){
      // console.log(this.$refs["topList"], this.topList);
      this.newsList = this.newsList.concat(this.topList);
      this.topList = [];
      this.$refs["topList"].$el.style.backgroundColor = "";
      this.newsListUpdate('');
    },
    clearSubTopList(){
      this.newsList = this.newsList.concat(this.subTopList);
      this.subTopList = [];
      this.$refs["subTopList"].$el.style.backgroundColor = "";
      this.newsListUpdate('');
    },
    clearList(type){
      if(type == 'entBatch3'){
        this.newsList = this.newsList.concat(this.entBatch3List);
        this.entBatch3List = [];
        this.newsListUpdate('');
      }else if(type == 'entBatch4'){
        this.entBatch4List = [];
      }
    },
    newsListUpdate(e){
      // console.log(e, this.newsList);
      if(e.removed) this.newsList.splice(e.removed.oldIndex + (this.currentx-1)*this.itemsPerPage, 1);
      if(e.added) this.newsList.splice(e.added.newIndex + (this.currentx-1)*this.itemsPerPage, 0, e.added.element);
      this.newsList.sort(function(a, b) {
        return b.d_pass2 - a.d_pass2;
      });
    },
    mainTopChange(e){
      console.log(this.topList)
      if(e.added){
        console.log('icon: ', e.added.element.icon)
        if(e.added.element.icon == '' && this.$route.query.category != 99){
          alert('그림이 없는 기사는 헤드라인에 배치될 수 없습니다.')
          this.topList = this.topList.filter(top => top.id != e.added.element.id);
          this.newsList = this.newsList.concat([e.added.element]);
          this.newsListUpdate('');
        }
      }
      this.topListChange();
    },
    topListChange(){
      // console.log(e, this.topList.length, this.subTopList.length)
      if(this.topList.length == this.numOfTop && this.subTopList.length == this.numOfSubTop){
        if(this.$route.query.category == 8){
          if((this.entBatch3List.length == 0 || this.entBatch3List.length == this.numOfEntBatch3) 
              && (this.entBatch4List.length == 0 || this.entBatch4List.length == this.numOfEntBatch4)){
            this.sendButtonDisable = false;
          }else{
            this.sendButtonDisable = true;
          }
        }else{
          this.sendButtonDisable = false;
        }
      }else if(this.numOfTop < 0) this.sendButtonDisable = false;
      else this.sendButtonDisable = true;
      
      if(this.middleSortFlg == 'auto'){
      this.subTopList.sort(function(a, b) {
        return b.d_pass2 - a.d_pass2;
      });
      }

      if(this.topList.length == this.numOfTop){
        // console.log(this.$refs["topList"].$el.style.borderColor);
        this.$refs["topList"].$el.style.backgroundColor = "rgba(100, 100, 255, 0.2)";
      }else this.$refs["topList"].$el.style.backgroundColor = "";
      if(this.numOfSubTop){
        if(this.subTopList.length == this.numOfSubTop){
          this.$refs["subTopList"].$el.style.backgroundColor = "rgba(100, 255, 100, 0.2)";
        }else this.$refs["subTopList"].$el.style.backgroundColor = "";
      }
    },
    addBatchImg(e, num){
      this.batchImgNum = num + 10;
      if(e.target.files.length ){
        this.files[0] = e.target.files[0];
        this.submitFiles(e.target.files);
      }
    },
    addFile(e){
      this.batchImgNum = 0;
      // console.log(e.target.files, e.target.files[0]);
      if(e.target.files.length ){
        this.files[0] = e.target.files[0];
        this.submitFiles(e.target.files);
      }
    },
    /*
      Determines if the drag and drop functionality is in the
      window
    */
    determineDragAndDropCapable(){
      /*
        Create a test element to see if certain events
        are present that let us do drag and drop.
      */
      var div = document.createElement('div');

      /*
        Check to see if the `draggable` event is in the element
        or the `ondragstart` and `ondrop` events are in the element. If
        they are, then we have what we need for dragging and dropping files.

        We also check to see if the window has `FormData` and `FileReader` objects
        present so we can do our AJAX uploading
      */
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window;
    },

    /*
      Submits the files to the server
    */
    submitFiles(files){
      /*
        Initialize the form data
      */
      let formData = new FormData();
      let thisIns = this;

      /*
        Iteate over any file sent over appending the files
        to the form data.
      */
      for( var i = 0; i < files.length; i++ ){
        let file = files[i];
        formData.append('file', file);
      }
      /*
        Make the request to the POST /file-drag-drop-instant URL
      */
     let targetUrl = '/api/newsPlace/uploadFile';
     if(thisIns.batchImgNum){ targetUrl = '/api/newsPlace/batchImgUpload' }
      this.$http.post( targetUrl,
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      ).then(function(response){
        console.log('SUCCESS!!', response);
        if(response.data.length){
          if(thisIns.batchImgNum == 0){
            thisIns.dailyTopic.image = response.data[0];
            thisIns.preview = "/api/photo/viewImages?filename=" + response.data[0];
          }else{
            thisIns.entBatch3List[thisIns.batchImgNum - 10].batchImg = "/api/photo/viewImages?filename=" + response.data[0];
            thisIns.entBatch3List.splice();
          }
        }
        // thisIns.getImagePreviews();
      })
      .catch(function(response){
        console.log('FAILURE!!', response);
      });
    },
    created() {
      this.currentx = 1;
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });

      var placeUrl = '' 
      this.naverRankingList = {
        hour: 0, list: []
      };
      this.searchVar.category1 = this.$route.query.category * 1 || '*';
      if(this.$route.query.category == 0 || this.$route.query.category == 99 || this.$route.query.category == 77 || this.$route.query.category == 78){
        this.dateRange.startDate = moment().subtract(1, 'month')
        this.searchVar.startDate = this.dateRange.startDate
        this.numOfEntBatch3 = 0;
        this.numOfEntBatch4 = 0;
        placeUrl = '/api/getNewsList/placeMain';
        if(this.$route.query.category == 0){
          this.numOfTop = 7;
          this.numOfSubTop = 18;
        }
        else if(this.$route.query.category == 99){
          this.numOfTop = -1;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 77){
          this.numOfTop = 6;
          this.numOfSubTop = 0;
          placeUrl = '/api/getNewsList/placeNaver';
          
          this.$http.post('/api/newsPlace/naverRanking', {})
            .then(function (response) {
              console.log('naverRanking:', response);
              thisIns.naverRankingList = response.data;
            }).catch(function (err){
              console.log(err)
            });
          
        }else if(this.$route.query.category == 78){
          this.numOfTop = 5;
          this.numOfSubTop = 0;
          placeUrl = '/api/getNewsList/placeDaum';
        }
      // }else if(this.$route.query.category == 10){
      }else if(this.$route.query.category == 999){
        this.numOfTop = 0;
        this.numOfSubTop = 0;
        this.numOfEntBatch3 = 0;
        this.numOfEntBatch4 = 0;
        placeUrl = '/api/getNewsList/placeProject';
      }else {
        placeUrl = '/api/getNewsList/place'

        this.dateRange.startDate = moment().subtract(1, 'month')
        this.searchVar.startDate = this.dateRange.startDate
        this.numOfEntBatch3 = 0;
        this.numOfEntBatch4 = 0;
        this.numOfSportsExBatch = 0;
        this.batchImg = [];
        
        if(this.$route.query.category == 1){
          this.numOfTop = 1;
          this.numOfSubTop = 10;
        }else if(this.$route.query.category == 3) {
          this.numOfTop = 1;
          this.numOfSubTop = 0;
          this.numOfEntBatch4 = 3;
        }else if(this.$route.query.category == 6) {
          this.numOfTop = 1;
          this.numOfSubTop = 0;
          this.numOfEntBatch3 = 3;
        }else if(this.$route.query.category == 8) {
          this.numOfTop = 1;
          this.numOfSubTop = 3;
          this.numOfEntBatch3 = 0;
          this.numOfEntBatch4 = 3;
        }else if(this.$route.query.category == 9) {
          this.numOfTop = 1;
          this.numOfSubTop = 3;
          // if(moment() < moment("2023-10-18")){
          //   this.numOfSportsExBatch = 3;
          // }
        }else if(this.$route.query.category < 9){
          this.numOfTop = 1;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 10) {
          this.numOfTop = 10;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category < 20) {
          this.numOfTop = 1;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 88){
          this.numOfTop = 3;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 99){
          this.numOfTop = -1;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 56){
          this.numOfTop = 1;
          this.numOfSubTop = 5;
        }else if(this.$route.query.category == 51){
          this.numOfTop = 3;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 52){
          this.numOfTop = 4;
          this.numOfSubTop = 0;
        }else if(this.$route.query.category == 55) {
          this.numOfTop = 4;
          this.numOfSubTop = 0;
          // if(moment() < moment("2024-08-18")){
          //   this.numOfSportsExBatch = 4;
          // }
        }
      }
      console.log('placeUrl:', placeUrl)
      this.topList = [];
      this.subTopList = [];
      this.sendButtonDisable = true;
      if(this.numOfTop < 0) this.sendButtonDisable = false;

      this.$http.post('/api/newsPlace/history', {category: thisIns.searchVar.category1})
        .then(function (response) {
          console.log('history:', response);
          thisIns.history = response.data;
        }).catch(function (err){
          console.log(err)
        });

      this.$http.post(placeUrl, this.searchVar)
        .then(function (response) {
          thisIns.numOfNews = response.data[0][0].news_count - thisIns.numOfTop - thisIns.numOfSubTop;
          thisIns.numOfPages = Math.ceil(thisIns.numOfNews / thisIns.itemsPerPage)
          thisIns.newsList = response.data[1];
          console.log('response.data:', response.data, thisIns.numOfPages)
          console.log('response:', response)

          thisIns.$http.post('/api/newsPlace/get', {category: thisIns.searchVar.category1})
            .then(function (response) {
              let tmpDatas = [];
              tmpDatas = response.data;
              thisIns.topList = tmpDatas.filter(data => data.news_grade == (thisIns.$route.query.category * 100 + 11).toString());
              thisIns.topList.forEach(data =>{
                var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
                // console.log(tmp);
                if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
              });
              // console.log(thisIns.topList);
              if(thisIns.numOfSubTop){
                thisIns.subTopList = tmpDatas.filter(data => data.news_grade == (thisIns.$route.query.category * 100 + 12).toString());
                thisIns.subTopList.forEach(data =>{
                  var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
                  // console.log(tmp);
                  if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
                });
                // console.log(thisIns.subTopList);
              }
              if(thisIns.numOfEntBatch3){
                thisIns.entBatch3List = tmpDatas.filter(data => data.news_grade == (thisIns.$route.query.category * 100 + 13).toString());
                thisIns.entBatch3List.forEach(data =>{
                  thisIns.batchImg.push(data.batchImg)
                  var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
                  // console.log(tmp);
                  if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
                });
              }
              if(thisIns.numOfEntBatch4){
                thisIns.entBatch4List = tmpDatas.filter(data => data.news_grade == (thisIns.$route.query.category * 100 + 14).toString());
                thisIns.entBatch4List.forEach(data =>{
                  var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
                  // console.log(tmp);
                  if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
                });
              }
              if(thisIns.sportsExNum == thisIns.searchVar.category1){
                thisIns.$http.post('/api/setting/getSetting', {code: thisIns.sportsExNum})
                .then(function (response){
                  console.log("medal data: ",response)
                  let medal = response.data;
                  for(const key of Object.keys(thisIns.medalData)){
                    let data = medal.find(e => e.name == key)
                    if(data){
                      thisIns.medalData[key] = data.value;
                    }
                  }
                  console.log("test", thisIns.medalData)
                }).catch(function (error) {
                  var errorText = error.toString();
                  if(errorText.includes('401')){
                    alert('로그인 해주세요!')
                    thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
                  }
                  thisIns.$vs.notify({
                    title:'Error',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                  thisIns.$vs.loading.close();  
                });
              }
              // if(thisIns.numOfSportsExBatch){
              //   thisIns.$http.post('/api/newsPlace/get', {category: thisIns.sportsExNum})
              //     .then(function (response) {
              //       thisIns.sportsExBatch = response.data;
              //       thisIns.sportsExBatch.forEach(data =>{
              //         var tmp = thisIns.newsList.findIndex(news => news.id == data.id)
              //         // console.log(tmp);
              //         if(tmp >= 0) thisIns.newsList.splice(tmp, 1);
              //       });
              //       return thisIns.$http.post('/api/setting/getSetting', {code: thisIns.sportsExNum})
              //     }).then(function (response){
              //       // console.log("medal data: ",response)
              //       let medal = response.data;
              //       for(const key of Object.keys(thisIns.medalData)){
              //         let data = medal.find(e => e.name == key)
              //         if(data){
              //           thisIns.medalData[key] = data.value;
              //         }
              //       }
              //     }).catch(function (error) {
              //       var errorText = error.toString();
              //       if(errorText.includes('401')){
              //         alert('로그인 해주세요!')
              //         thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
              //       }
              //       thisIns.$vs.notify({
              //         title:'Error',
              //         text: error,
              //         color:'danger',
              //         iconPack: 'feather',
              //         icon:'icon-alert-circle'})
              //       thisIns.$vs.loading.close();  
              //     });
              // }
              // console.log(thisIns.newsList);
              // if(thisIns.$route.query.category == 0){
              //     thisIns.$http.post('/api/newsPlace/dailyTopic')
              //       .then(function (response) {
              //         console.log('response: ', response)
              //         thisIns.dailyTopic.selected = thisIns.seriesArray.find(e => e.code == response.data[0].seriesNum);
              //         thisIns.dailyTopic.title = response.data[0].title;
              //         thisIns.dailyTopic.image = response.data[0].image;
              //         thisIns.preview = "/api/photo/viewImages?filename=" + response.data[0].image;
              //         thisIns.$vs.loading.close();  
              //       })
              //       .catch(function (error) {
              //         var errorText = error.toString();
              //         if(errorText.includes('401')){
              //           alert('로그인 해주세요!')
              //           thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
              //         }
              //         thisIns.$vs.notify({
              //           title:'Error',
              //           text: error,
              //           color:'danger',
              //           iconPack: 'feather',
              //           icon:'icon-alert-circle'})
              //         thisIns.$vs.loading.close();  
              //       });
              // }else {
              //   thisIns.$vs.loading.close();  
              // }
              thisIns.$vs.loading.close();  
            })
            .catch(function (error) {
              var errorText = error.toString();
              if(errorText.includes('401')){
                alert('로그인 해주세요!')
                thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
              }
              thisIns.$vs.notify({
                title:'Error',
                text: error,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
              thisIns.$vs.loading.close();  
            });

            console.log('thisIns.newsList:', thisIns.newsList)
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });  
      if(this.$refs["topList"]){
        this.$refs["topList"].$el.style.backgroundColor = "";
      }
      if(this.$refs["subTopList"]){
        this.$refs["subTopList"].$el.style.backgroundColor = "";
      }
    },
    handleWindowResize(event) {
        this.windowWidth = event.currentTarget.innerWidth;
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.seriesArray = this.$store.state.series;
    this.created();
  },
  mounted() {
    this.$nextTick(() => {
        window.addEventListener('resize', this.handleWindowResize);
    });
    this.isMounted = true;
    // if(this.$route.query.category == 0){
    //   /*
    //     Determine if drag and drop functionality is capable in the browser
    //   */
    //   this.dragAndDropCapable = this.determineDragAndDropCapable();

    //   /*
    //     If drag and drop capable, then we continue to bind events to our elements.
    //   */
    //   if( this.dragAndDropCapable ){
    //     /*
    //       Listen to all of the drag events and bind an event listener to each
    //       for the fileform.
    //     */
    //     ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
    //       /*
    //         For each event add an event listener that prevents the default action
    //         (opening the file in the browser) and stop the propagation of the event (so
    //         no other elements open the file in the browser)
    //       */
    //       this.$refs.fileform.addEventListener(evt, function(e){
    //         e.preventDefault();
    //         e.stopPropagation();
    //       }.bind(this), false);
    //     }.bind(this));

    //     /*
    //       Add an event listener for drop to the form
    //     */
    //     this.$refs.fileform.addEventListener('drop', function(e){
    //       /*
    //         Capture the files from the drop event and add them to our local files
    //         array.
    //       */
    //       this.files[0] = e.dataTransfer.files[0];
    //       console.log(e.dataTransfer.files);
    //       this.submitFiles(e.dataTransfer.files);
    //     }.bind(this));
    //   }
    // }
  }
}
</script>

<style lang="scss">
#newsPlace{
  .cursor-move{
    min-height: 150px;
  }
  #newsList{
    .cursor-move{
      min-height: 1000px;
    }
  }
  #topList, #subTopList{
    .cursor-move{
      min-height: 250px;
    }
  }
  .dailyTopic{  
    form{
      display: inline-block;
      height: 150px;
      width: 150px;
      background: #ccc;
      text-align: center;
      border-radius: 4px;
      vertical-align: bottom;
    }
    .drop-files{
      p{
        padding-top: 50px;
        height: 100%;
      }
    }
    input[type="file"] { /* 파일 필드 숨기기 */ 
      position: absolute; 
      width: 1px; 
      height: 1px; 
      padding: 0; 
      margin: -1px; 
      overflow: hidden; 
      clip:rect(0,0,0,0); 
      border: 0; 
    }
    progress{
      width: 150px;
      margin: auto;
      display: block;
      margin-top: -20px;
    }
  }
  .batchImgUpload{
    position: absolute;  
    form{
      display: inline-block;
      height: 30px;
      width: 180px;
      background: #ccc;
      opacity: 0.5;
      text-align: center;
      border-radius: 4px;
      vertical-align: bottom;
    }
    .drop-files{
      p{
        padding-top: 6px;
        height: 100%;
      }
      img{
        border-radius: 3px;
        width: 180px;
        height: 30px;
        object-fit: cover; 
        object-position: 0% 0%;
      }
    }
    input[type="file"] { /* 파일 필드 숨기기 */ 
      position: absolute; 
      width: 1px; 
      height: 1px; 
      padding: 0; 
      margin: -1px; 
      overflow: hidden; 
      clip:rect(0,0,0,0); 
      border: 0; 
    }
  }
  @media (max-width: 700px) {
    // font-size: 0.7em;
    .vx-row > .vx-col{
      padding: 0 0.2rem;
    }
  }
}
.newsPlace-preview{
  .greyFilter{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.35;
  }
  .lineSliderContainer{
    width: 786px;
    margin: 30px 0;
    .lineSliderDiv{
      width: 100%;
      height: 130px;
      position: relative;
      flex-shrink: 0;
      img{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 0;
      }
      .seriesName{
        position: absolute;
        top: 30px;
        left: 50%;
        p{
          margin-left: -50%;
          width: 100%;
          font-size: 18px;
          line-height: 20px;
          color: #15FCE9;
          box-sizing: border-box;
          padding: 5px 15px;
          border: 1px solid;
          box-shadow: inset -3px 3px 2px 0px rgba(0,0,0,0.5), -3px 3px 2px 0px rgba(0,0,0,0.5);
          text-shadow: -3px 3px 2px #000000;
        }
      }
      .title{
        position: absolute;
        top: 70px;
        width: 100%;
        p{
          width: 100%;
          padding: 0 50px;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 25px;
          max-height: 1.5em;
          line-height: 1.5em;
          color: #FFFFFF;
          box-sizing: border-box;
          text-shadow: 0px 2px 4px #000000;
        }
      }
    }
  }
}
</style>